import {StatisticsService} from '../../shared/statistics.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {TvstatTicket} from '../../shared/models/tvstatTicket';

@Component({
  selector: 'ticket',
  templateUrl: 'ticket.component.html',
  styleUrls: ['./ticket.component.css']
})

export class TicketComponent implements OnInit {
  constructor(private statisticsService: StatisticsService, private toastSrv: ToastrService, private activatedRoute: ActivatedRoute) {

  }

  timeLeft = 0;
  interval;
  public tvstatTickets: TvstatTicket;

  ngOnInit(): void {
    this.getTvStatInformation();
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.getTvStatInformation();
        this.timeLeft = 90; // 90
      }
    }, 1000);
  }

  getTvStatInformation() {
    this.statisticsService.GetOperationsCallInformation().subscribe(x => {
      this.tvstatTickets = x;
    });
  }

  getCriticalEmployees() {
    if (this.tvstatTickets != null) {
      return this.tvstatTickets.employees.filter(x => x.ticketAllCritical > 0);
    } else {
      return [];
    }
  }

  getHighEmployees() {
    if (this.tvstatTickets != null) {
      return this.tvstatTickets.employees.filter(x => x.ticketAllHigh > 0);
    } else {
      return [];
    }

  }


}
