<div class="row">
  <div class="col-md-6">
      <iframe id="scaled-frame" style="width: 100%; height: 100%" src="https://flexfoneapi.itoperators.dk/snpd/"></iframe>
  </div>
  <div class="col-md-6">
    <div id="wrapper">
      <h3 class="last-update">{{lastUpdate | danishDate}}</h3>
      <div id="hosts">
        <div class="dash" [class.redBackground]="this.critical > 0">
          <span class="center_text">Kritisk<br><span class="big">{{critical}}</span></span>
        </div>
        <div class="dash" [class.redBackground]="this.high > 0">
          <span class="center_text">Høj<br><span class="big">{{high}}</span></span>
        </div>
        <div class="dash" [class.redBackground]="this.medium > 0">
          <span class="center_text">Mellem<br><span class="big">{{medium}}</span></span>
        </div>
        <div class="dash" [class.redBackground]="this.low > 0">
          <span class="center_text">Lav<br><span class="big">{{low}}</span></span>
        </div>
      </div>
    </div>
  </div>
</div>
