import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
@Injectable()
export class BasicFunctionService {

  static Token = '';
  public note = 'feedback';
  public check = 'check';

  // PROD
   public baseApiUrl = 'https://erp.itoperators.dk/api/TvstatStatistics/';

  // DEV
  //public baseApiUrl = 'https://erpdev.itoperators.dk/api/TvstatStatistics/';

  // DEV-LOCALHOST
   //public baseApiUrl = 'https://localhost:5001/api/TvstatStatistics/';


  public headerDict = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Origin': '*',
    Token: BasicFunctionService.Token,
  };

  public requestOptions = {
    headers: new HttpHeaders(this.headerDict),
  };

  // Convert , to .
  static commaToDot(stringName) {
    return stringName.toString().replace(',', '.');
  }

  // Convert . to ,
  static dotToComma(stringName) {
    return stringName.toString().replace('.', ',');
  }

  // Convert milliseconds to hours
  static convertMilliToHours(time) {
    return time / 1000 / 60 / 60;
  }
}
