import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CriticalTicketDialogComponent} from "./dialogs/criticalTicketDialog.component";
import {InfoTicket} from "./models/infoTicket";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  public modalOpen = false;

  openCriticalTicketDialog(ticket: InfoTicket, employeeGroupId: number) {
    return this.dialog.open(CriticalTicketDialogComponent, {
      width: '600px',
      panelClass: 'critical-dialog-container',
      disableClose: true,
      hasBackdrop: false,
      position: {top: '10vh'},
      autoFocus: true,
      data: {
        ticket: ticket,
        employeeGroupId: employeeGroupId
      }
    });
  }
}
