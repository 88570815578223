<div style="padding-top: 4rem">
  <div class="card card-login mx-auto mt-5">
    <div class="card-header"><img src="../../../assets/logo.png" alt=""></div>
    <div class="card-body">
      <form [formGroup]="loginForm" class="mb-4" autocomplete="off" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="userName">Brugernavn</label>
          <input class="form-control" id="userName" name="userName" formControlName="username" tabindex="1" required
                 autoFocus>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" class="form-control" id="password" name="password" formControlName="password"
                 tabindex="2" required>
        </div>
        <button type="submit" tabindex="3" class="btn btn-primary btn-block" [disabled]="loginForm.invalid">Goto
        </button>
      </form>
      <div class="text-center"></div>
    </div>
  </div>
</div>
