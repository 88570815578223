<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-9">
      <div class="row mobile-flex-column">
        <!-- Logo -->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="text-center"><img height="45px" style="margin:16px 0" src="assets/logo.png"></div>
              <div class="text-center"><h3 id="time">time</h3></div>
            </div>
          </div>
        </div>

        <!-- Idag -->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title"><span>Konsulenttimer dag</span></h5>
              <div class="bg-primary text-white mt-2 flex-container"><span class="col flex-1"> I dag </span> <span
                class="col flex-1 text-center font-weight-bold font-x-large"> {{ statistics?.todayThisYear| danishNumber }} </span>
                <span
                  class="col flex-1 text-right font-large"> Indeks
                  {{ statistics?.todayThisYear / statistics?.todayLastYear * 100 || "0" | number: '1.0-0' }}
        </span></div>
              <div class="bg-primary text-white mt-2 flex-container">
                <div class="col flex-1"> Sidste år</div>
                <div class="col flex-1 text-center font-large"> {{ statistics?.todayLastYear| danishNumber }}</div>
                <div class="col flex-1"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Måned -->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title"><span>Konsulenttimer måned</span></h5>
              <div class="bg-primary text-white mt-2 flex-container">
                <div class="col flex-1"> Måned</div>
                <div
                  class="col flex-1 text-center font-weight-bold font-x-large"> {{ statistics?.monthThisYear| danishNumber }}
                </div>
                <div class="col flex-1 text-right font-large"> Indeks
                  {{ statistics?.monthThisYear / statistics?.monthLastYear * 100 || "0" | number: '1.0-0' }}
                </div>
              </div>
              <div class="bg-primary text-white mt-2 flex-container">
                <div class="col flex-1"> Sidste år til dato</div>
                <div class="col flex-1 text-center font-large"> {{ statistics?.monthLastYear| danishNumber }}</div>
                <div class="col flex-1">Måned: {{ statistics?.monthLastYearTotal| danishNumber }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- År -->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title"><span>Konsulenttimer år</span></h5>
              <div class="bg-primary text-white mt-2 flex-container">
                <div class="col flex-1"> År</div>
                <div
                  class="col flex-1 text-center font-weight-bold font-x-large"> {{ statistics?.thisYear| danishNumber }}
                </div>
                <div class="col flex-1 text-right font-large"> Indeks
                  {{ statistics?.thisYear / statistics?.lastYear * 100 || "0" | number: '1.0-0' }}
                </div>
              </div>
              <div class="bg-primary text-white mt-2 flex-container">
                <div class="col flex-1"> Sidste år til dato</div>
                <div class="col flex-2 text-center font-large"> {{ statistics?.lastYear| danishNumber }}</div>
                <div class="col flex-1 text-center font-medium">
                  Året: {{ statistics?.lastYearTotal| danishNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!---- Errors --->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="errors-title card-title"><span>Errors</span></h5>
              <div class="bg-primary text-white mt-2 flex-container">
                <div *ngIf="errorGlobalLog?.item1 != 0"
                     class="errors col flex-1 text-center font-weight-bold font-x-large">
                  <p>{{ errorGlobalLog?.item1 }} / {{ errorGlobalLog?.item2 }}</p></div>
                <div *ngIf="errorGlobalLog?.item1 == 0 && errorGlobalLog?.item2 != 0"
                     class="noErrors col flex-1 text-center font-weight-bold font-x-large">
                  0 / {{ errorGlobalLog.item2 }}
                </div>
                <div *ngIf="errorGlobalLog?.item1 == 0 && errorGlobalLog?.item2 == 0"
                     class="noErrors col flex-1 text-center font-weight-bold font-x-large">
                  <i class="far fa-smile"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!------------------------------------------------------------------------------------------------------>
      <div class="row mobile-flex-column">
        <div class="col d-none d-lg-block konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <div class="text-center">
                <h5 class="card-title"><span>Jenkins builds</span></h5>
                <div *ngFor="let build of jenkinsBuild">
                  <h6>{{ build.name }} ({{ build.percentage }})</h6>
                </div>
                <div *ngFor="let build of jenkinsFailed">
                  <h6 style="color: red">{{ build }} (FAILED)</h6>
                </div>
                <div *ngFor="let build of jenkinsSuccess">
                  <h6 style="color: limegreen">{{ build }} (SUCCESS)</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col konsulenttimer mb-4">
          <div class="card h-100 ">
            <div class="card-body">
              <h5 class="card-title"><span>Deadline projekter</span></h5>
              <div *ngFor="let project of projectDeadline1"
                   class="{{project.deadline1}} bg-primary text-white mt-2 flex-container">
                <div class="col-auto">{{ project.name | truncate:[28, '...'] }}</div>
                <div
                  class="col text-right font-large">{{ project.deadline | danishDate: false  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title"><span>Deadline projekter</span></h5>
              <div *ngFor="let project of projectDeadline2"
                   class="{{project.deadline1 }} bg-primary text-white mt-2 flex-container">
                <div class="col-auto">{{ project.name | truncate:[28, '...']  }}</div>
                <div
                  class="col text-right font-large">{{ project.deadline | danishDate: false }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title"><span>Deadline projekter</span></h5>
              <div *ngFor="let project of projectDeadline3"
                   class="{{project.deadline1 }} bg-primary text-white mt-2 flex-container">
                <div class="col-auto">{{ project.name | truncate:[28, '...'] }}</div>
                <div
                  class="col text-right font-large">{{ project.deadline | danishDate: false }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Errors Website -->
        <div class="col konsulenttimer mb-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="errors-title card-title"><span>Errors websites</span></h5>
              <div class="bg-primary text-white mt-2 flex-container">
                <div *ngIf="(this.websiteStatus.down+this.websiteStatus.maintenance) != 0" class="errors col flex-1 text-center font-weight-bold font-x-large">
                  <p>{{ (this.websiteStatus.down + this.websiteStatus.maintenance) }} / {{ this.websiteStatus.up }}</p>
                </div>

                <div *ngIf="(this.websiteStatus.down+this.websiteStatus.maintenance) == 0" class="noErrors col flex-1 text-center font-weight-bold font-x-large">
                  <div class="">
                    <i class="far fa-smile"></i>
                  </div>
                </div>
                {{ this.websiteStatus.down + this.websiteStatus.maintenance }} / {{ this.websiteStatus.up }}

              </div>
            </div>
          </div>
        </div>
      </div>

      <!------------------------------------------------------------------------------------------------------>


      <div class="row mobile-flex-column">
        <!-- Konsulenter -->
        <div *ngFor="let statisticsTvStatEmployee of statistics?.employeeStatistic" class="col mb-4">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <div class="containerImg">
                <img *ngIf="statisticsTvStatEmployee.name"
                     src="https://erp.itoperators.dk/assets/img/employee/{{statisticsTvStatEmployee?.name}}.jpg"
                     appImgFallback alt="{{statisticsTvStatEmployee?.name}}" class="billede-konsulent">
                <img *ngIf="!statisticsTvStatEmployee.name"
                     src="https://erp.itoperators.dk/assets/img/employee/poul.jpg"
                     appImgFallback alt="{{statisticsTvStatEmployee?.name}}" class="billede-konsulent">
              </div>
              <div class="card-half bg-light text-center ikon mb-2 ml-2 mobile-margin">
                <i *ngIf="statisticsTvStatEmployee.today >= statistics.bestEmployeeAmount && statisticsTvStatEmployee.today > 0" class="fa fa-trophy"></i>
                <i *ngIf="statisticsTvStatEmployee.today < statistics.bestEmployeeAmount && statisticsTvStatEmployee.today > 0" class="far fa-smile"></i>
                <i *ngIf="statisticsTvStatEmployee.external && statisticsTvStatEmployee.today == 0" class="far fa-meh-blank"></i>
                <i *ngIf="!statisticsTvStatEmployee.external && statisticsTvStatEmployee.today == 0" class="far fa-frown"></i>
              </div>

              <!-- temp under -->
              <div [class.bg-success]="statisticsTvStatEmployee.today > 0"
                   [class.bg-danger]="statisticsTvStatEmployee.today == 0"
                   class="card-half text-white mb-2 ml-2 p-2">
                <!--p>Timer idag</p>
                <span class="font-x-large"> {{statisticsTvStatEmployee.today| danishNumber}} </span> <span
                class="font-large">{{statisticsTvStatEmployee.todayNb| danishNumber}}</span><span
                class="font-large">{{statisticsTvStatEmployee.todayInternal| danishNumber}}</span></div>
              <div class="card-half bg-success text-white mb-2 p-2">
                <p>Uge</p>
                <span class="font-x-large">{{statisticsTvStatEmployee.week| danishNumber}}</span> <span
                class="font-large">{{statisticsTvStatEmployee.weekNb| danishNumber}}</span><span
                class="font-large">{{statisticsTvStatEmployee.weekInternal| danishNumber}}</span></div>
              <div class="card-half bg-success text-white mb-2 ml-2 p-2">
                <p>Måned</p>
                <span class="font-x-large">{{statisticsTvStatEmployee.month| danishNumber}}</span> <span
                class="font-large">{{statisticsTvStatEmployee.monthNb| danishNumber}}</span><span
                class="font-large">{{statisticsTvStatEmployee.monthInternal| danishNumber}}</span></div>
              <div class="card-half bg-success text-white mb-2 p-2">
                <p>År</p>
                <span class="font-x-large">{{statisticsTvStatEmployee.year| danishNumber}}</span> <span
                class="font-large">{{statisticsTvStatEmployee.yearNb| danishNumber}}</span><span
                class="font-large">{{statisticsTvStatEmployee.yearInternal| danishNumber}}</span></div>
              <div class="card-half bg-primary text-white mb-2 ml-2 p-2">
                <p>Måned sidste år</p>
                <span class="font-x-large">{{statisticsTvStatEmployee.monthLastYear| danishNumber}}</span> <span
                class="font-large">{{statisticsTvStatEmployee.monthNbLastYear| danishNumber}}</span><span
                class="font-large">{{statisticsTvStatEmployee.monthInternalLastYear| danishNumber}}</span></div-->
                <div class="row no-gutters">
                  <span class="col">Timer idag</span>
                  <span class="col">I/F</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large"> {{ statisticsTvStatEmployee.today| danishNumber }} </span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.todayNb| danishNumber }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.todaySubscription| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.todayInternal| danishNumber }}</span>
                </div>
              </div>
              <div class="card-half bg-success text-white mb-2 p-2">
                <div class="row no-gutters">
                  <span class="col">Uge</span>
                  <span class="col">I/F</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.week| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.weekNb| danishNumber }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.weekSubscription| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.weekInternal| danishNumber }}</span>
                </div>
              </div>
              <div class="card-half bg-success text-white mb-2 ml-2 p-2">
                <div class="row no-gutters">
                  <span class="col">Måned</span>
                  <span class="col">I/F</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.month| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.monthNb| danishNumber }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.monthSubscription| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.monthInternal| danishNumber }}</span>
                </div>
              </div>
              <div class="card-half bg-success text-white mb-2 p-2">
                <div class="row no-gutters">
                  <span class="col">År</span>
                  <span class="col">I/F</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.year| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.yearNb| danishNumber }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.yearSubscription| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.yearInternal| danishNumber }}</span>
                </div>
              </div>
              <div class="card-half bg-primary text-white mb-2 ml-2 p-2">
                <p>Måned sidste år</p>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.monthLastYear| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.monthNbLastYear| danishNumber }}</span>
                </div>
                <div class="row no-gutters">
                  <span class="col font-x-large">{{ statisticsTvStatEmployee.monthSubscriptionLastYear| danishNumber }}</span>
                  <span class="col font-large">{{ statisticsTvStatEmployee.monthInternalLastYear| danishNumber }}</span>
                </div>
              </div>
              <div class="card-half bg-light text-dark p-2">
                <p>Mål måned</p>
                <span class="customFont">
              <span *ngIf="statisticsTvStatEmployee.goalYear != 0">
              {{ statisticsTvStatEmployee.goalMonthProgress | danishNumber }} / {{ statisticsTvStatEmployee.goalYear / 12 | danishNumber }}
                ({{ removeDecimals(statisticsTvStatEmployee.goalMonthProgress / (statisticsTvStatEmployee.goalYear / 12) * 100) }}%) </span>
            <span
              *ngIf="statisticsTvStatEmployee.goalYear == 0">Møde</span>
            </span>
              </div>

              <div class="card-half bg-light text-dark ml-2 p-2">
                <p>Mål år</p>
                <span class="customFont">
              <span *ngIf="statisticsTvStatEmployee.goalYear != 0">
                {{ statisticsTvStatEmployee.goalYearProgress | danishNumber }} / {{ statisticsTvStatEmployee.goalYear | danishNumber }}
                ({{ removeDecimals(statisticsTvStatEmployee.goalYearProgress / statisticsTvStatEmployee.goalYear * 100) }}%)
              </span>
              <span *ngIf="statisticsTvStatEmployee.goalYear == 0">Ingen mål i livet</span>
            </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Deadline projekter -->

        <!-- Projekt opgaver uden tildeling -->
        <!-- Select the next 5 tasks with overdue deadline or near deadline -->
        <!-- Sort it for each customer and make a loop that echos the below html
        <div class="col mb-4">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <h5 class="card-title"><span>Projekt opgaver uden tildeling</span></h5>
              <div class="konsulentWrap">
                <table class="tasks">
                  <thead>
                  <th>Titel</th>
                  <th>Deadline</th>
                  <th>Prioritet</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let project of projectsFree">
                    <td>{{project.title | truncate:[30, '...']}}</td>
                    <td [class.overdue]="overdue(project)">{{project.deadline | danishDate: false}}</td>
                    <td>
                      <div class="{{project.taskPriority.name}} mx-auto"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Empty column for empty space filling-->
        <!--<div class="col d-none d-lg-block mb-4">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <h5 class="card-title"><span></span></h5>
              <div class="konsulentWrap">
              </div>
            </div>
          </div>
        </div>-->


      </div>

      <!-- Bottom row -->

      <div class="row mobile-flex-column">

        <!-- Employee tasks overdue -->
        <div class="col mb-4" *ngFor="let taskDeadline of taskDeadline">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <h5 class="card-title"><span>{{ taskDeadline.name }}</span></h5>
              <div class="konsulentWrap">
                <table class="tasks">
                  <thead>
                  <th>Titel</th>
                  <th>Deadline</th>
                  <th>Prioritet</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let task of taskDeadline?.task">
                    <td>{{ task.title | truncate:[30, '...'] }}</td>
                    <td [class.overdue]="overdue(task)">{{ task.deadline | danishDate: false }}</td>
                    <td>
                      <div class="{{task?.taskPriority?.name}} mx-auto"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Adhoc opgaver uden tildeling -->
        <!-- Select the next 5 tasks with overdue deadline or near deadline -->
        <!-- Sort it for each customer and make a loop that echos the below html
        <div class="col mb-4">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <h5 class="card-title"><span>Ad-hoc uden tildeling</span></h5>
              <div class="konsulentWrap">
                <table class="tasks">
                  <thead>
                  <tr>
                    <th>Titel</th>
                    <th>Deadline</th>
                    <th>Prioritet</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let task of taskFree">
                    <td>{{task.title | truncate:[30, '...']}}</td>
                    <td [class.overdue]="overdue(task)">{{task.deadline | danishDate: false}}</td>
                    <td>
                      <div class="{{task.taskPriority.name}} mx-auto"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Empty column for empty space filling-->
        <!--<div class="col d-none d-lg-block mb-4">
          <div class="card customShadow h-100">
            <div class="card-body itop">
              <h5 class="card-title"><span></span></h5>
              <div class="konsulentWrap">

              </div>
            </div>
          </div>
        </div>-->

      </div>
    </div>

    <!-- Job Historik -->
    <div class="col-3">

      <div class="card customShadow h-50">
        <div class="card-body itop">
          <h6 class="card-title"><span>Igangværende jobs</span></h6>
          <div class="konsulentWrap">
            <table class="jobs">
              <thead>
              <th>Job</th>
              <th>Kunde</th>
              <th>Start</th>
              <th>Fuldført</th>
              <th>Interval</th>
              <th>Varighed</th>
              </thead>
              <tbody>
              <tr *ngFor="let job of startedJobHistory" [class.yellow]="jobHistorySlow(job)">
                <td>{{ job.jobName | truncate:[30, '...'] }}</td>
                <td>{{ job.customerName | truncate:[8, '...']  }}</td>
                <td>{{ job.eventTime | timeOnly }}</td>
                <td>{{ job.completedTime | timeOnly }}</td>
                <td>{{ job.interval + " sek"}}</td>
                <td>{{ job.duration != null ? (job.duration + " sek") : "" }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--  Failed Jobs -->

      <div class="card customShadow h-50">
        <div class="card-body itop">
          <h6 class="card-title"><span>Fejlet jobs sidste 72 timer</span></h6>
          <div class="konsulentWrap">
            <table class="jobs">
              <thead>
              <th>Job</th>
              <th>Kunde</th>
              <th>Start</th>
              </thead>
              <tbody>
              <tr class="gray" *ngFor="let job of failedJobHistory">
                <td>{{ job.jobName | truncate:[30, '...'] }}</td>
                <td>{{ job.customerName | truncate:[8, '...']  }}</td>
                <td>{{ job.eventTime | danishDate }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="col-3">



  </div>

</div>

<criticalTicketDialogWrapper [employeeGroupId]="1"></criticalTicketDialogWrapper>
