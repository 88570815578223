import {ActivatedRoute, Router} from '@angular/router';

import {Jwt} from '../../shared/models/jwt';

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {first, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  tokenValue = new Subject();
  public localIp;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
  }

  loggedIn(): boolean {
    if (localStorage.getItem('token') != null && localStorage.getItem('token') != '') {
      return true;
    }
    return false;
  }

  login(username: string, password: string, localIp: string) {
    return this.http.post<Jwt>('https://erp.itoperators.dk/api/employees/login', {username, password, localIp})
      .pipe(map(user => {
// store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user.token);
        localStorage.setItem('name', user.name);
        localStorage.setItem('username', user.username);
        localStorage.setItem('employeeId', user.employeeId);
        localStorage.setItem('expires', user.expires);
        this.token = user.token;
      }));
  }

  renew() {
    return this.http.post<Jwt>('https://erp.itoperators.dk/api/employees/RenewLoginSession', null)
      .pipe(map(user => {
// store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', user.token);
        localStorage.setItem('name', user.name);
        localStorage.setItem('username', user.username);
        localStorage.setItem('employeeId', user.employeeId);
        localStorage.setItem('expires', user.expires);
        this.token = user.token;
      }));
  }

  logout() {
// remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('username');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('expires');
  }

// Saving token in a subject so it possible to subscribe to change
  set token(value) {
    this.tokenValue.next(value);
    if (value == '') {
      this.logout();
    } else {
      localStorage.setItem('token', value);
    }
  }

  get token() {
    return localStorage.getItem('token');
  }
}
