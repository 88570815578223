import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../auth/authenticationService';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, public authenticationService: AuthenticationService, private formBuilder: FormBuilder, private toastSrv: ToastrService) {
  }

  ngOnInit() {
    this.router.navigate(['/']);

    /*this.route.queryParamMap.subscribe(queryParams => {
      this.authenticationService.getLocalIp(queryParams.get('web'));
    });

    if (this.authenticationService.loggedIn()) {
      this.router.navigate(['/']);
    }*/
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password, this.authenticationService.localIp)
        .pipe(first())
        .subscribe(
          data => {
            this.router.navigate(['/']);
          },
          error => {
            this.toastSrv.error(error.error, 'Fejl');
          });
    }
  }
}
