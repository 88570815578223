import {StatisticsService} from '../../shared/statistics.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {StatisticsTvStat} from '../../shared/models/statistics/statisticsTvStat';
import {ErrorLogService} from '../../shared/errorLog.service';
import {WebsiteStatus} from "../../shared/models/websiteStatus";
import {last} from "rxjs";

@Component({
  selector: 'ninja',
  templateUrl: 'ninja.component.html',
  styleUrls: ['./ninja.component.css']
})

export class NinjaComponent implements OnInit, OnDestroy {

  public critical: number = 0;
  public high: number = 0;
  public medium: number = 0;
  public low: number = 0;
  private timer: any = 0;
  private timerReload: any = 0;
  public lastUpdate: Date;

  constructor(private statisticsService: StatisticsService) {

  }

  ngOnInit() {
    let parent = this;
    this.timerReload = setInterval(function () {
      window.location.reload();
    }, 60000);
    this.load();
  }

  load() {
    this.statisticsService.getNinjaStatus().subscribe(success => {
      this.critical = success.critical;
      this.high = success.high;
      this.medium = success.medium;
      this.low = success.low;
      this.lastUpdate = success.lastUpdate;
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

}
