<div class="container p-0" *ngIf="this.tvstatTickets != null">
  <div class="d-flex justify-content-around">
    <img height="100px" src="assets/tvstat-stats.png" style="margin:60px 0">
  </div>
  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Kritiske</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <ng-container *ngIf="this.tvstatTickets.ticketGraphCritical > 0">
              <p class="count text-red errors">{{this.tvstatTickets.ticketGraphCritical}}</p>
            </ng-container>
            <ng-container *ngIf="this.tvstatTickets.ticketGraphCritical == 0">
              <p class="count text-darkgrey"> 0 </p>
            </ng-container>
          </div>
        </div>
        <div class="card-footer" style="display: block;">
          <div class="d-flex justify-content-around">
            <div class="d-flex">
              <ng-container *ngFor="let employee of this.getCriticalEmployees()">
                <img alt="{{employee.name}}" class="rounded-circle" src="https://erp.itoperators.dk/assets/img/employee/{{employee.name}}.jpg">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Høje</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <ng-container *ngIf="this.tvstatTickets.ticketGraphHigh > 0">
              <p class="count text-red">{{this.tvstatTickets.ticketGraphHigh}}</p>
            </ng-container>
            <ng-container *ngIf="this.tvstatTickets.ticketGraphHigh == 0">
              <p class="count text-darkgrey"> 0 </p>
            </ng-container>
          </div>
        </div>
        <div class="card-footer" style="display: block;">
          <div class="d-flex justify-content-around">
            <div class="d-flex">
              <!-- Billede af konsulterne med høje -->
              <ng-container *ngFor="let employee of this.getHighEmployees()">
                <img alt="{{employee.name}}" class="rounded-circle" src="https://erp.itoperators.dk/assets/img/employee/{{employee.name}}.jpg">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Opgaver idag</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-blue">{{this.tvstatTickets.ticketOpen}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Overskredne idag</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-red">{{this.tvstatTickets.ticketOverDue}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Overskredet +7</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-red">{{this.tvstatTickets.ticketOverDue7Days}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Afventende</h2>
          <i class="fas fa-ticket-alt"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-darkgrey">{{this.tvstatTickets.ticketWaiting}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>~Svartid</h2>
          <i class="fas fa-phone"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-darkgrey">
              {{this.tvstatTickets.responseTime}}<small>s</small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>~Længde</h2>
          <i class="fas fa-phone"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-darkgrey">
              {{this.tvstatTickets.callsDuration}}<small>m</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Samtaler</h2>
          <i class="fas fa-phone"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-blue">{{this.tvstatTickets.callsToday}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mb-3">
      <div class="card">
        <div class="card-title">
          <h2>Misset opkald</h2>
          <i class="fas fa-phone"></i>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-around">
            <p class="count text-red">{{this.tvstatTickets.missedCalls}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <iframe src="https://backup.itoperators.dk/scroll?token=kvV9GDRHeZ0FyCZuswZzghj4eQplm0hf" title="Backup of doom" style="height: 68em; width: 100%"></iframe>
    </div>
  </div>
</div>

<criticalTicketDialogWrapper [employeeGroupId]="2"></criticalTicketDialogWrapper>
