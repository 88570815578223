import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StatisticsService} from "../statistics.service";
import {InfoTicket} from "../models/infoTicket";

@Component({
  selector: 'criticalTicketDialog',
  templateUrl: './criticalTicketDialog.component.html',
  styleUrls: ['./criticalTicketDialog.component.scss']
})
export class CriticalTicketDialogComponent implements OnInit, OnDestroy{
  ticket: InfoTicket;
  employeeGroupId: number;
  autoRefresh: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<CriticalTicketDialogComponent>,
              private statisticsService: StatisticsService,) {
  }

  ngOnInit(): void {
    this.ticket = this.data.ticket;
    this.employeeGroupId = this.data.employeeGroupId;
    this.refreshData();
  }

  ngOnDestroy(): void {
    clearTimeout(this.autoRefresh);
  }

  refreshData() {
    this.statisticsService.getFreeOrNotStartedCriticalTask(this.employeeGroupId).subscribe(success => {
      if (success == null) {
        this.dialogRef.close(false);
      }

      this.ticket = success;
    });

    this.autoRefresh = setTimeout(() => {
      this.refreshData();
    }, 30000)
  }

  employeeNames() {
    return this.ticket.taskEmployees.map(x => x.employee.name ).join(', ');
  }
}
