import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IndexComponent} from './modules/index/index.component';
import {LoginComponent} from './core/login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BasicFunctionService} from './shared/basicFunction.service';
import {registerLocaleData} from '@angular/common';

import {ReverseStr} from './shared/pipe/danishNumbers';
import {WebComponent} from './modules/web/web.component';
import {TicketComponent} from './modules/ticket/ticket.component';
import {ImgFallbackDirective} from './shared/pipe/imgFallback';
import {DanishDate} from './shared/pipe/danishDate';
import {TimeOnly} from './shared/pipe/timeOnly';
import {TruncatePipe} from './shared/pipe/truncate';
import {default as auth} from './auth-config.js';
import {NgChartsModule} from 'ng2-charts';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
  MsalGuardConfiguration, MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule, MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import {Ticket2Component} from "./modules/ticket2/ticket2.component";
import {NinjaComponent} from "./modules/ninja/ninja.component";
import {CriticalTicketDialogComponent} from "./shared/dialogs/criticalTicketDialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {CriticalTicketDialogWrapperComponent} from "./shared/dialogs/criticalTicketDialogWrapper.component";
import {SagTicketComponent} from "./modules/sag-tickets/sag-ticket.component";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: auth.credentials.clientId,
      authority: 'https://login.microsoftonline.com/' + auth.credentials.tenantId,
      redirectUri: auth.configuration.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  if (BasicFunctionService.Token !== '') {
    protectedResourceMap.set(auth.resources.noApi.resourceUri, auth.resources.noApi.resourceScopes);
  } else {
    protectedResourceMap.set(auth.resources.todoListApi.resourceUri, auth.resources.todoListApi.resourceScopes);
  }
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {interactionType: InteractionType.Redirect};
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    WebComponent,
    TicketComponent,
    LoginComponent,
    Ticket2Component,
    NinjaComponent,
    SagTicketComponent,
    ReverseStr,
    ImgFallbackDirective,
    DanishDate,
    TimeOnly,
    TruncatePipe,
    CriticalTicketDialogWrapperComponent,
    CriticalTicketDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MsalModule,
    NgChartsModule,
    MatDialogModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }, MsalService,
    MsalGuard,
    MsalBroadcastService,
    BasicFunctionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
