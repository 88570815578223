import { Component, OnDestroy, OnInit } from '@angular/core';
import {TicketService} from "../../shared/ticket.service";
import {ChartConfiguration, ChartData, ChartType} from "chart.js";
import {PriorityCounter} from "../../shared/models/priorityCounter";

@Component({
  selector: 'sag-ticket',
  templateUrl: './sag-ticket.component.html',
  styleUrls: ['./sag-ticket.component.css']
})
export class SagTicketComponent implements OnInit {

  constructor(private ticketService: TicketService) { }

  timeLeft = 0;
  interval;
  acceloticket: PriorityCounter;
  erpticket: PriorityCounter;

  ngOnInit(): void {
    this.loadData();
    this.startTimer();
  }

  loadData() {
    this.ticketService.GetAcceloTicketInformation().subscribe(success => {
      this.acceloticket = success;
      console.log(success);
    })
    this.ticketService.GetSagitoTicketInformation()
      .subscribe(success => {
        this.erpticket = success;
        console.log(success);
      }, () => {
      });
  }

  startTimer() {
    this.time();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.time();
        this.loadData();
        this.timeLeft = 90; // 90
      }
    }, 60000);
  }

  time() {
    var location = document.getElementById('time');
    var d = new Date();
    var m = d.getMinutes();
    var h = d.getHours();
    location.innerText =
      ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2)
  }
}
