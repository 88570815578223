import {StatisticsTvStat} from './models/statistics/statisticsTvStat';

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {StatisticsTicketsOperation} from './models/statistics/statisticsTicketsOperation';
import {BasicFunctionService} from './basicFunction.service';
import {WebsiteStatus} from "./models/websiteStatus";
import {TvstatTicket} from "./models/tvstatTicket";
import {Ninja} from "./models/ninja";
import {InfoTicket} from "./models/infoTicket";

@Injectable({
  providedIn: 'root'
})

export class StatisticsService {

  constructor(private http: HttpClient, private basicFunctionsService: BasicFunctionService) {
  }

  GetStatistics() {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'tvstat',
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: StatisticsTvStat) => {
          return data;
        }));
  }

  GetStatisticsDeveloper() {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'tvstatDeveloper',
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: StatisticsTvStat) => {
          return data;
        }));
  }

  GetTicketsOperation() {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'ticketsOperation',
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: StatisticsTicketsOperation) => {
          return data;
        }));
  }

  GetOperationsCallInformation() {
    return this.http.get<TvstatTicket>(this.basicFunctionsService.baseApiUrl + 'ticket', this.basicFunctionsService.requestOptions);
  }

  GetTicketsDoneToday() {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'website/ticket/day',
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: number) => {
          return data;
        }));
  }

  getCustomerWebProjects(webProject, state) {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'customersWebProject/' + webProject + '/' + state,
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getFreeOrNotStartedCriticalTask(employeeGroupId) {
    return this.http.get<InfoTicket>(this.basicFunctionsService.baseApiUrl + 'taskCritical/' + employeeGroupId,
      this.basicFunctionsService.requestOptions);
  }

  getTaskFreeAdHoc(employeeGroupId) {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'taskFree/adHoc/' + employeeGroupId,
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getWebTaskFree(employeeGroupId) {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'taskFree/project/' + employeeGroupId, this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getWebTaskDeadline(employeeGroupId) {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'taskDeadline/' + employeeGroupId, this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getJenkins() {
    return this.http.get(this.basicFunctionsService.baseApiUrl + 'jenkins', this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getFailedJenkins() {
    return this.http.get<string[]>(this.basicFunctionsService.baseApiUrl + 'jenkins/failed', this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  // This is used for getting local ip
  getLocalIp() {
    return this.http.get('https://backup.itoperators.dk/ip',
      this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any) => {
          return data;
        }));
  }


  getSuccessJenkins() {
    return this.http.get<string[]>(this.basicFunctionsService.baseApiUrl + 'jenkins/success', this.basicFunctionsService.requestOptions)
      .pipe(
        map((data: any[]) => {
          return data;
        }));
  }

  getWebsiteStatus() {
    return this.http.get<WebsiteStatus>(this.basicFunctionsService.baseApiUrl + "websiteStatus", this.basicFunctionsService.requestOptions)
  }

  getNinjaStatus() {
    return this.http.get<Ninja>(this.basicFunctionsService.baseApiUrl + "ninja", this.basicFunctionsService.requestOptions)
  }


}
