import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './modules/index/index.component';
import {LoginComponent} from './core/login/login.component';
import {WebComponent} from './modules/web/web.component';
import {TicketComponent} from './modules/ticket/ticket.component';
import {MsalGuard} from '@azure/msal-angular';
import {Ticket2Component} from "./modules/ticket2/ticket2.component";
import {NinjaComponent} from "./modules/ninja/ninja.component";
import {SagTicketComponent} from "./modules/sag-tickets/sag-ticket.component";

const routes: Routes = [
  {path: '', component: IndexComponent, canActivate: [MsalGuard]},
  {path: 'token', component: IndexComponent},
  {path: 'web', component: WebComponent, canActivate: [MsalGuard]},
  {path: 'webToken', component: WebComponent},
  {path: 'ticket', component: TicketComponent, canActivate: [MsalGuard]},
  {path: 'ticket2', component: TicketComponent},
  {path: 'login', component: LoginComponent},
  {path: 'ninja', component: NinjaComponent},
  {path: 'sag-ticketToken', component: SagTicketComponent},
  {path: 'sag-ticket', component: SagTicketComponent, canActivate: [MsalGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
