import {StatisticsService} from '../../shared/statistics.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {StatisticsTvStat} from '../../shared/models/statistics/statisticsTvStat';
import {ErrorLogService} from '../../shared/errorLog.service';
import {WebsiteStatus} from "../../shared/models/websiteStatus";
import {JobHistory} from "../../shared/models/jobHistory";

@Component({
  selector: 'web',
  templateUrl: 'web.component.html',
  styleUrls: ['./web.component.css']
})

export class WebComponent implements OnInit {
  constructor(private statisticsService: StatisticsService,
              private toastSrv: ToastrService,
              private errorLogService: ErrorLogService) {
  }

  timeLeft = 0;
  interval;
  public statistics: StatisticsTvStat;
  public picture: any[];
  errorCount = 3;
  errorGlobalLog: any;
  errorWebGlobalLog: any;
  jobHistory: JobHistory[];
  failedJobHistory: JobHistory[];
  startedJobHistory: JobHistory[];
  public projectDeadline1;
  public projectDeadline2;
  public projectDeadline3;
  public projectsFree;
  public taskFree;
  public taskDeadline;
  public jenkinsBuild = [];
  public now = new Date();
  public nowPlus1Day = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  public jenkinsFailed: string[] = [];
  public jenkinsSuccess: string[] = [];
  public websiteStatus: WebsiteStatus = {
    up: 0,
    down: 0,
    maintenance: 0,
    pause: 0,
    unknown: 0
  } as WebsiteStatus;

  ngOnInit(): void {
    this.time();
    // this.projectDeadlines();
    this.getWebsiteStatus()
    this.freeTasks();
    this.webTaskDeadline();
    this.statisticsService.GetStatisticsDeveloper()
      .subscribe(success => {
        this.statistics = success;
      }, () => {
        this.errorCount--;
        if (this.errorCount === 0) {
          location.reload();
        }
      });

    // error Log
    this.errorLogService.GetErrors()
      .subscribe(success => {
        this.errorGlobalLog = success;
      });

    this.errorLogService.GetWebErrors()
      .subscribe(success => {
        this.errorWebGlobalLog = success;
      });

    // gets last 50 jobs

    this.errorLogService.GetJobHistory()
      .subscribe(success => {

        this.jobHistory = success
        this.filterStartedJobHistory()
        // Ensure we are slicing to get the last 17 items
        this.startedJobHistory = this.startedJobHistory.length > 17 ? this.startedJobHistory.slice(0, -(this.startedJobHistory.length-17)) : this.startedJobHistory;
      });

    this.errorLogService.GetFailedJobHistory()
      .subscribe(success => {
        // Ensure we are slicing to get the last 17 items
        this.failedJobHistory = success.length > 17 ? success.slice(0, -(success.length-17)) : success;
      });

    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.time();
      if (this.timeLeft%10 == 0) {
        this.getJenkins();
      }
      if (this.timeLeft%5 == 0) {
        this.errorLogService.GetJobHistory()
          .subscribe(success => {
            this.jobHistory = success
            this.filterStartedJobHistory()
            // Ensure we are slicing to get the last 17 items
            this.startedJobHistory = this.startedJobHistory.length > 17 ? this.startedJobHistory.slice(0, -(this.startedJobHistory.length-17)) : this.startedJobHistory;
          });
        this.errorLogService.GetFailedJobHistory()
          .subscribe(success => {
            // Ensure we are slicing to get the last 17 items
            this.failedJobHistory = success.length > 17 ? success.slice(0, -(success.length-17)) : success;
          });
      }
      if (this.timeLeft > 0) {
        this.timeLeft -= 5;
      } else {
        this.getWebsiteStatus();
        this.projectDeadlines();
        this.freeTasks();
        this.webTaskDeadline();
        this.statisticsService.GetStatisticsDeveloper()
          .subscribe(success => {
            this.statistics = success;
          }, () => {
            this.errorCount--;
            if (this.errorCount === 0) {
              location.reload();
            }
          });

        // error Log
        this.errorLogService.GetErrors()
          .subscribe(success => {
            this.errorGlobalLog = success;
          });

        this.errorLogService.GetWebErrors()
          .subscribe(success => {
            this.errorWebGlobalLog = success;
          });
        this.timeLeft = 60; // 90
      }
    }, 5000);
  }

  projectDeadlines() {
    this.statisticsService.getCustomerWebProjects(1, 0).subscribe(
      success => {
        const now = new Date();
        this.projectDeadline1 = [];
        this.projectDeadline2 = [];
        this.projectDeadline3 = [];
        success.forEach((value, index) => {
          if (new Date(value.deadline).getTime() < now.getTime()) {
            value.deadline1 = 'deadline1';
          }
          if (index === 0 || index === 1) {
            this.projectDeadline1.push(value);
          } else if (index === 2 || index === 3) {
            this.projectDeadline2.push(value);
          } else if (index === 4 || index === 5) {
            this.projectDeadline3.push(value);
          }
        });
      });
  }

  freeTasks() {
    this.statisticsService.getWebTaskFree(1).subscribe(
      success => {
        this.projectsFree = success.filter((item, index) => index < 6);
      });

    this.statisticsService.getTaskFreeAdHoc(1).subscribe(
      success => {
        this.taskFree = success.filter((item, index) => index < 6);
      });
  }

  webTaskDeadline() {
    this.statisticsService.getWebTaskDeadline(1).subscribe(
      success => {
        this.taskDeadline = success;
      });
  }

  getJenkins() {
    this.statisticsService.getFailedJenkins().subscribe(
      success => {
        this.jenkinsFailed = success;
      }
    )

    this.statisticsService.getSuccessJenkins().subscribe(
      success => {
        this.jenkinsSuccess = success;
      }
    )

    this.statisticsService.getJenkins().subscribe(
      success => {
        if (success) {
          this.jenkinsBuild = success;
        } else {
          this.jenkinsBuild = [];
        }
      });
  }

  overdue(task) {
    return new Date(task.deadline) < this.nowPlus1Day;
  }

  jobHistoryGood(jobHistory): boolean {
    // A job has gone well if the completed time is filled and duration is filled, but duration is less than interval
    return jobHistory.completedTime != null && jobHistory.duration != null && jobHistory.duration < jobHistory.interval;
  }

  jobHistoryBad(jobHistory): boolean {
    // A job has gone bad if the duration/completed time is not filled
    let currentTime = new Date().getTime();
    let eventTimePlusInterval = new Date(jobHistory.eventTime).getTime() + jobHistory.interval * 1000;
    return jobHistory.completedTime == null && eventTimePlusInterval < currentTime;
  }

  jobHistorySlow(jobHistory: JobHistory): boolean {
    // A job has gone well, but took too long if the duration is greater than the interval
    // or eventTime + interval < time now and completed time is null
    let currentTime = new Date().getTime();
    let eventTimePlusInterval = new Date(jobHistory.eventTime).getTime() + jobHistory.interval * 1000;

    return (jobHistory.completedTime != null && jobHistory.duration != null && jobHistory.duration > jobHistory.interval)
      || (eventTimePlusInterval > currentTime && jobHistory.completedTime == null);
  }





  time() {
    var location = document.getElementById('time');
    var d = new Date();
    var m = d.getMinutes();
    var h = d.getHours();
    location.innerText =
      ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2)
  }

  removeDecimals (value: number) {
    return Math.round(value);
  }

  getWebsiteStatus() : void {
    this.statisticsService.getWebsiteStatus().subscribe(success => {
      this.websiteStatus = success;
    });
  }

  // used for the seperate job history array which will only show jobs that have not finished yet
  filterStartedJobHistory() {
    this.startedJobHistory = this.jobHistory.filter(job => !this.jobHistoryGood(job) && !this.jobHistoryBad(job));
  }
}
