import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BasicFunctionService} from './basicFunction.service';
import {JobHistory} from "./models/jobHistory";

@Injectable({
    providedIn: 'root'
})

export class ErrorLogService {

    constructor(private http: HttpClient, private basicFunctionsService: BasicFunctionService) {
    }

    GetErrors() {
        return this.http.get(this.basicFunctionsService.baseApiUrl + 'errorCount', this.basicFunctionsService.requestOptions)
            .pipe(
                map((data: any) => {
                    return data;
                }));
    }

    GetWebErrors() {
        return this.http.get(this.basicFunctionsService.baseApiUrl + 'errorWebCount', this.basicFunctionsService.requestOptions)
            .pipe(
                map((data: any) => {
                    return data;
                }));
    }

  GetJobHistory() {
    return this.http.get<JobHistory[]>(this.basicFunctionsService.baseApiUrl + "jobLog", this.basicFunctionsService.requestOptions)
  }

  GetFailedJobHistory() {
    return this.http.get<JobHistory[]>(this.basicFunctionsService.baseApiUrl + "jobLogFailed", this.basicFunctionsService.requestOptions)
  }
}
