import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BasicFunctionService} from './shared/basicFunction.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tvstat';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    const parameters = new URLSearchParams(window.location.search);
    let token = parameters.get("token");
    if (token != null) {
      BasicFunctionService.Token = token;
    }

  }
}

