import {StatisticsService} from "../statistics.service";
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DialogService} from "../dialog.service";
import {InfoTicket} from "../models/infoTicket";

@Component({
  selector: 'criticalTicketDialogWrapper',
  templateUrl: './criticalTicketDialogWrapper.component.html',
})

export class CriticalTicketDialogWrapperComponent implements OnInit, OnDestroy {
  constructor(private statisticsService: StatisticsService,
              private dialogService: DialogService) {
  }

  private autoRefresh: any;
  private criticalTicket: InfoTicket;
  @Input() public employeeGroupId: number;


  ngOnDestroy(): void {
    clearTimeout(this.autoRefresh);
  }

  ngOnInit(): void {
    this.refreshData();
  }

  private refreshData() {
    if (!this.dialogService.modalOpen) {
      this.statisticsService.getFreeOrNotStartedCriticalTask(this.employeeGroupId).subscribe(success => {
        if (success == null) {
          return;
        }

        this.criticalTicket = success;

        this.dialogService.modalOpen = true;
        this.dialogService.openCriticalTicketDialog(this.criticalTicket, this.employeeGroupId)
          .afterClosed().subscribe(() => {
          this.dialogService.modalOpen = false;
        });
      });
    }

    this.autoRefresh = setTimeout(() => {
      this.refreshData();
    }, 30000)
  }
}
