import {Component, OnInit, ViewChild} from '@angular/core';
import {TicketService} from "../../shared/ticket.service";
import {ChartType, ChartData, ChartConfiguration} from "chart.js";
import {BaseChartDirective} from 'ng2-charts';

@Component({
    selector: 'ticket2',
    templateUrl: 'ticket2.component.html',
    styleUrls: ['./ticket2.component.css']
})

export class Ticket2Component implements OnInit {
    constructor(private ticketService: TicketService) {
    }

    timeLeft = 0;
    interval;
    ticket;

    public barChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        scales: {
            x: {},
            y: {}
        }
    };
    public barChartType: ChartType = 'bar';
    public barChartData: ChartData<'bar'> = {
        labels: [],
        datasets: [],
    };
    public barChartDataTicketToday: ChartData<'bar'> = {
        labels: [],
        datasets: [],
    };


    ngOnInit(): void {
        this.startTimer();
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.ticketService.GetTicketInformation()
                    .subscribe(success => {
                        this.ticket = success;
                        console.log(success);

                        let temp = [
                            {data: [], label: 'KRITISK', stack: 'a', backgroundColor: 'black'},
                            {data: [], label: 'HØJ', stack: 'a', backgroundColor: '#C6345E'},
                            {data: [], label: 'MELLEM', stack: 'a', backgroundColor: '#0096BE'},
                            {data: [], label: 'LAV', stack: 'a', backgroundColor: '#b6b2b2'},
                        ];

                        let employeeNames = [];
                        success.employees.forEach(employee => {
                            employeeNames.push(employee.name);
                            temp[3].data.push(employee.ticketLow);
                            temp[2].data.push(employee.ticketMedium);
                            temp[1].data.push(employee.ticketHigh);
                            temp[0].data.push(employee.ticketCritical);
                        });

                        this.barChartData = {
                            labels: employeeNames,
                            datasets: temp
                        };

                        this.barChartDataTicketToday = {
                            labels: ['KRITISK', 'HØJ', 'MELLEM', 'LAV'],
                            datasets: [
                                {
                                    data: [success.ticketGraphCritical],
                                    label: 'KRITISK',
                                    stack: 'a',
                                    backgroundColor: 'black'
                                },
                                {
                                    data: [0, success.ticketGraphHigh],
                                    label: 'HØJ',
                                    stack: 'a',
                                    backgroundColor: '#C6345E'
                                },
                                {
                                    data: [0, 0, success.ticketGraphMedium],
                                    label: 'MELLEM',
                                    stack: 'a',
                                    backgroundColor: '#0096BE'
                                },
                                {
                                    data: [0, 0, 0, success.ticketGraphLow],
                                    label: 'LAV',
                                    stack: 'a',
                                    backgroundColor: '#b6b2b2'
                                },
                            ]
                        };
                    }, () => {
                    });
                this.timeLeft = 90; // 90
            }
        }, 1000);
    }
}
