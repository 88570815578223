import {StatisticsService} from '../../shared/statistics.service';
import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {StatisticsTvStat} from '../../shared/models/statistics/statisticsTvStat';
import {Router} from '@angular/router';
@Component({
  selector: 'index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.css']
})

export class IndexComponent implements OnInit {
  constructor(private statisticsService: StatisticsService, private toastSrv: ToastrService, private router: Router) {
  }

  timeLeft = 0;
  interval;
  public statistics: StatisticsTvStat;
  errorCount = 3;
  noWork;

  ngOnInit(): void {

    this.statisticsService.GetStatistics()
      .subscribe(success => {
        if (success) {
          this.statistics = success;
        }
      });

    this.statisticsService.getLocalIp()
      .subscribe(success => {
        if (success) {
          if (success == '192.168.156.77') {
            this.router.navigate(['/web']);
          }
        }
      });
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.time();
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.statisticsService.GetStatistics()
          .subscribe(success => {
            this.statistics = success;
          }, () => {
            this.errorCount--;
            if (this.errorCount === 0) {
              location.reload();
            }
            this.toastSrv.warning('Fejl ved opdatering', 'Tvstat');
          });
        this.timeLeft = 90; // 90
      }
    }, 1000);
  }

  time() {
    var location = document.getElementById('time');
    var d = new Date();
    var m = d.getMinutes();
    var h = d.getHours();
    location.innerText =
      ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2)
  }

  removeDecimals (value: number) {
    return Math.round(value);
  }
}
