<div class="alert-wrapper">
  <h5 class="card-title"><span>Ny kritisk opgave:</span></h5>
  <h2 class="font-weight-normal mb-3">{{ticket.title}}</h2>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span *ngIf="ticket.project == null" class="input-group-text">Kunde:</span>
      <span *ngIf="ticket.project != null" class="input-group-text">Projekt:</span>
    </div>
    <input *ngIf="ticket.project == null" type="text" disabled class="form-control" value="{{ticket.customer.name}}">
    <input *ngIf="ticket.project != null" type="text" disabled class="form-control" value="{{ticket.project.name}}">
  </div>

  <div *ngIf="ticket.taskEmployees.length > 0" class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">Tildelt:</span>
    </div>
    <input type="text" class="form-control" disabled value="{{employeeNames()}}">
  </div>
</div>
