<div class="container-fluid">
  <div class="row">


    <div class="col">
      <img height="100px" class="d-block mx-auto my-5" src="assets/ssologo.png">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="dash" [class.greyBackground]="acceloticket?.ticketGraphLow > 0">
        <span class="center_text">Helpdesk kø<br><span class="big">{{ acceloticket?.ticketGraphLow }}</span></span>
      </div>
    </div>
    <div class="col">
      <div class="dash" [class.simpleSolutionBackground]="acceloticket?.ticketGraphMedium > 0">
        <span class="center_text">Helpdesk nye<br><span class="big">{{ acceloticket?.ticketGraphMedium }}</span></span>
      </div>
    </div>
    <div class="col">
      <div class="dash" [class.redBackground]="acceloticket?.ticketGraphHigh > 500">
        <span class="center_text">Assigned total<br><span class="big">{{ acceloticket?.ticketGraphHigh }}</span></span>
      </div>
    </div>
  </div>


  <div class="row">
    <div class=" col ">
      <img height="100px" class="d-block mx-auto my-5" src="assets/it-operators.png" >
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="dash" [class.greyBackground]="erpticket?.ticketGraphLow > 0">
        <span class="center_text">Lav<br><span class="big">{{ erpticket?.ticketGraphLow }}</span></span>
      </div>
    </div>
    <div class="col">
      <div class="dash" [class.itopBackground]="erpticket?.ticketGraphMedium > 0">
        <span class="center_text">Mellem<br><span class="big">{{ erpticket?.ticketGraphMedium }}</span></span>
      </div>
    </div>
    <div class="col">
      <div class="dash" [class.redBackground]="erpticket?.ticketGraphHigh > 9">
        <span class="center_text">Høj<br><span class="big">{{ erpticket?.ticketGraphHigh }}</span></span>
      </div>
    </div>
  </div>
</div>
<div class="text-center"><h3 id="time">time</h3></div>

<criticalTicketDialogWrapper [employeeGroupId]="2"></criticalTicketDialogWrapper>
