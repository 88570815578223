<div *ngIf="noWork" style="width:100%;height:0;padding-bottom:58%;position:relative;">
  <iframe src="https://giphy.com/embed/45gODt1krqCOI" width="100%" height="100%" style="position:absolute"
          frameBorder="0"
          class="giphy-embed" allowFullScreen></iframe>
</div>

<div class="container" *ngIf="!noWork">
  <div class="row justify-content-md-center my-4">
    <!-- Logo -->
    <div class="col konsulenttimer">
      <div class="card">
        <div class="card-body">
          <div class="text-center"><img height="45px" style="margin:16px 0" src="assets/logo.png"></div>
          <div class="text-center"><h3 id="time">time</h3></div>
        </div>
      </div>
    </div>
    <!-- Idag -->
    <div class="col konsulenttimer mobil-margin-top">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title"><span>Konsulenttimer dag</span></h5>
          <div class="bg-primary text-white mt-2 flex-container"><span class="col flex-1"> I dag </span> <span
            class="col flex-1 text-center font-weight-bold font-x-large"> {{statistics?.todayThisYear| danishNumber}} </span>
            <span
              class="col flex-1 text-right font-large"> Indeks
              {{statistics?.todayThisYear / statistics?.todayLastYear * 100 || "0" | number: '1.0-0'}}
             </span></div>
          <div class="bg-primary text-white mt-2 flex-container">
            <div class="col flex-1"> Sidste år</div>
            <div class="col flex-1 text-center font-large"> {{statistics?.todayLastYear| danishNumber}}</div>
            <div class="col flex-1"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Måned -->
    <div class="col konsulenttimer mobil-margin-top">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title"><span>Konsulenttimer måned</span></h5>
          <div class="bg-primary text-white mt-2 flex-container">
            <div class="col flex-1"> Måned</div>
            <div
              class="col flex-1 text-center font-weight-bold font-x-large"> {{statistics?.monthThisYear| danishNumber}}</div>
            <div class="col flex-1 text-right font-large"> Indeks
              {{statistics?.monthThisYear / statistics?.monthLastYear * 100 || "0" | number: '1.0-0'}}
            </div>
          </div>
          <div class="bg-primary text-white mt-2 flex-container">
            <div class="col flex-1"> Sidste år til dato</div>
            <div class="col flex-1 text-center font-large"> {{statistics?.monthLastYear| danishNumber}}</div>
            <div class="col flex-1">Måned: {{statistics?.monthLastYearTotal| danishNumber}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- År -->
    <div class="col konsulenttimer mobil-margin-top">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title"><span>Konsulenttimer år</span></h5>
          <div class="bg-primary text-white mt-2 flex-container">
            <div class="col flex-1"> År</div>
            <div
              class="col flex-1 text-center font-weight-bold font-x-large"> {{statistics?.thisYear| danishNumber}}</div>
            <div class="col flex-1 text-right font-large"> Indeks
              {{statistics?.thisYear / statistics?.lastYear * 100 || "0" | number: '1.0-0'}}
            </div>
          </div>
          <div class="bg-primary text-white mt-2 flex-container">
            <div class="col flex-1"> Sidste år til dato</div>
            <div class="col flex-2 text-center font-large"> {{statistics?.lastYear| danishNumber}}</div>
            <div class="col flex-1 text-center font-medium"> Året: {{statistics?.lastYearTotal| danishNumber}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dagens tal -->
    <div class="col konsulenttimer dagens-tal mobil-margin-top">
      <div class="card" style="height: 8rem;">
        <div class="card-body">
          <h5 class="card-title d-flex justify-content-between">
            <span>Kundetal i år</span>
            <!-- <span>Kage</span>
            <span>Ticket lukket</span> -->
          </h5>
          <h2 class="text-primary text-center"> <!-- d-flex justify-content-between -->
            <span>{{statistics?.customersThisYear}}</span>
            <!-- <span><ng-container *ngIf="cakeDay != 0">{{cakeDay}}</ng-container>
              {{cakeHour}} {{cakeMinutes}} {{cakeSeconds}}</span>
            <span>{{statistics?.ticketClosedThisYear}}</span> -->
          </h2>
        </div>
      </div>
    </div>


    <!-- Row slut -->
  </div>
  <div class="row">
    <!-- Konsulent -->
    <div *ngFor="let statisticsTvStatEmployee of statistics?.employeeStatistic" class="col-lg-2 mb-2">
      <!-- 5 col: col-2dot4 -->
      <div class="card test">
        <div class="card-body itop">
          <div class="containerImg">
            <img *ngIf="statisticsTvStatEmployee.name" src="https://erp.itoperators.dk/assets/img/employee/{{statisticsTvStatEmployee?.name}}.jpg" appImgFallback alt="{{statisticsTvStatEmployee?.name}}" class="billede-konsulent">
            <img *ngIf="!statisticsTvStatEmployee.name" src="https://erp.itoperators.dk/assets/img/employee/poul.jpg" appImgFallback alt="{{statisticsTvStatEmployee?.name}}" class="billede-konsulent">
          </div>
          <div class="card-half bg-light text-center ikon mb-2 ml-2 mobile-margin">
            <i *ngIf="statisticsTvStatEmployee.today >= statistics.bestEmployeeAmount && statisticsTvStatEmployee.today > 0" class="fa fa-trophy"></i>
            <i *ngIf="statisticsTvStatEmployee.today < statistics.bestEmployeeAmount && statisticsTvStatEmployee.today > 0" class="far fa-smile"></i>
            <i *ngIf="statisticsTvStatEmployee.external && statisticsTvStatEmployee.today == 0" class="far fa-meh-blank"></i>
            <i *ngIf="!statisticsTvStatEmployee.external && statisticsTvStatEmployee.today == 0" class="far fa-frown"></i>
          </div>

          <!-- temp under -->
          <div [class.bg-success]="statisticsTvStatEmployee.today > 0"
               [class.bg-danger]="statisticsTvStatEmployee.today == 0"
               class="card-half text-white mb-2 ml-2 p-2">
            <div class="row no-gutters">
              <span class="col">Timer idag</span>
              <span class="col">I/F</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large"> {{statisticsTvStatEmployee.today| danishNumber}} </span>
              <span class="col font-large">{{statisticsTvStatEmployee.todayNb| danishNumber}}</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.todaySubscription| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.todayInternal| danishNumber}}</span>
            </div>
          </div>

          <div class="card-half bg-success text-white mb-2 p-2">
            <div class="row no-gutters">
              <span class="col">Uge</span>
              <span class="col">I/F</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.week| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.weekNb| danishNumber}}</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.weekSubscription| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.weekInternal| danishNumber}}</span>
            </div>
          </div>
          <div class="card-half bg-success text-white mb-2 ml-2 p-2">
            <div class="row no-gutters">
              <span class="col">Måned</span>
              <span class="col">I/F</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.month| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.monthNb| danishNumber}}</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.monthSubscription| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.monthInternal| danishNumber}}</span>
            </div>
          </div>
          <div class="card-half bg-success text-white mb-2 p-2">
            <div class="row no-gutters">
              <span class="col">År</span>
              <span class="col">I/F</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.year| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.yearNb| danishNumber}}</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.yearSubscription| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.yearInternal| danishNumber}}</span>
            </div>
          </div>
          <div class="card-half bg-primary text-white mb-2 ml-2 p-2">
            <p>Måned sidste år</p>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.monthLastYear| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.monthNbLastYear| danishNumber}}</span>
            </div>
            <div class="row no-gutters">
              <span class="col font-x-large">{{statisticsTvStatEmployee.monthSubscriptionLastYear| danishNumber}}</span>
              <span class="col font-large">{{statisticsTvStatEmployee.monthInternalLastYear| danishNumber}}</span>
            </div>
          </div>

          <div class="card-half bg-light text-dark p-2">
            <p>Mål måned</p>
            <span class="customFont">
              <span *ngIf="statisticsTvStatEmployee.goalYear != 0">
              {{statisticsTvStatEmployee.goalMonthProgress | danishNumber}} / {{statisticsTvStatEmployee.goalYear / 12 | danishNumber}} ({{removeDecimals(statisticsTvStatEmployee.goalMonthProgress / (statisticsTvStatEmployee.goalYear / 12) * 100)}}%) </span>
            <span
              *ngIf="statisticsTvStatEmployee.goalYear == 0">Møde</span>
            </span>
          </div>

          <div class="card-half bg-light text-dark ml-2 p-2">
            <p>Mål år</p>
            <span class="customFont">
              <span *ngIf="statisticsTvStatEmployee.goalYear != 0">
                {{removeDecimals(statisticsTvStatEmployee.goalYearProgress)}} / {{removeDecimals(statisticsTvStatEmployee.goalYear)}} ({{ removeDecimals(statisticsTvStatEmployee.goalYearProgress / statisticsTvStatEmployee.goalYear * 100) }}%)
              </span>
              <span *ngIf="statisticsTvStatEmployee.goalYear == 0"> Ingen mål i livet</span>
            </span>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

<criticalTicketDialogWrapper [employeeGroupId]="2"></criticalTicketDialogWrapper>
