import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeOnly'})
export class TimeOnly implements PipeTransform {
  transform(value: any) {
    if (value && value != 'Invalid Date') {
      const timePart = String(value).split('T')[1];
      if (timePart) {
        const time = timePart.split('+')[0].split('.')[0];
        return time;
      }
    }
    return value;
  }
}
