<div class="container p-0">
    <div class="d-flex justify-content-around"><img height="100px" src="assets/tvstat-stats.png"
                                                    style="margin:60px 0"></div>
    <div class="row">
        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Kritiske</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p *ngIf="ticket != null && ticket?.ticketGraphCritical??0 > 0"
                           class="count text-red errors"> {{ticket?.ticketGraphCritical}}
                        </p>
                        <p *ngIf="ticket?.ticketGraphCritical == 0"
                           class="count text-darkgrey">0</p>
                    </div>
                </div>
                <div class="card-footer" style="display: block;">
                    <div class="d-flex justify-content-around">
                        <div class="d-flex">
                            <!-- Billede af konsulterne med kritiske -->
                            <ng-container *ngFor="let employee of ticket?.employees">
                                <ng-container *ngIf="employee.ticketAllCritical > 0">
                                    <img alt="{{employee.name}}" class="rounded-circle"
                                         src="https://erp.itoperators.dk/assets/img/employee/{{employee.name}}.jpg">
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Høje</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p *ngIf="ticket?.ticketGraphHigh > 0"
                           class="count text-red">{{ticket?.ticketGraphHigh}}</p>
                        <p *ngIf="ticket?.ticketGraphHigh == 0"
                           class="count text-darkgrey">0</p>
                    </div>
                </div>
                <div class="card-footer" style="display: block;">
                    <div class="d-flex justify-content-around">
                        <div class="d-flex">
                            <!-- Billede af konsulterne med høje -->
                            <ng-container *ngFor="let employee of ticket?.employees">
                                <ng-container *ngIf="employee.ticketAllHigh > 0">
                                    <img alt="{{employee.name}}" class="rounded-circle"
                                         src="https://erp.itoperators.dk/assets/img/employee/{{employee.name}}.jpg">
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row">

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Opgaver idag</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p
                                class="count text-blue">{{ticket?.ticketOpen}}
                        </p>
                        <!--<p
                                class="count text-darkgrey">0</p>-->
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-down text-green"></i><span
                                class="text-green">5 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Overskredne idag</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-red">{{ticket?.ticketOverDue}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-down text-green"></i><span
                                class="text-green">5 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Overskredet +7</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-red">{{ticket?.ticketOverDue7Days}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up text-red"></i><span class="text-red">2 idag</span><i
                                class="fas fa-exchange-alt"></i><span> 7 dage</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Afventende</h2>
                    <i class="fas fa-ticket-alt"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-darkgrey">{{ticket?.ticketWaiting}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-ellipsis-h "></i><span class="">0 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Ticket fordeling idag</h2>
                </div>
                <div class="card-body">
                    <!--<div class="d-flex justify-content-around"><img src="assets/preview1.png"></div>-->
                    <canvas baseChart
                            [data]="barChartData"
                            [options]="barChartOptions"
                            [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </div>

        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Ticket prioriteter idag</h2>
                </div>
                <div class="card-body">
                    <canvas baseChart
                            [data]="barChartDataTicketToday"
                            [options]="barChartOptions"
                            [type]="barChartType">
                    </canvas>
                    <!--<div class="d-flex justify-content-around"><img src="assets/preview1.png"></div>-->
                </div>
            </div>
        </div>
    </div>

    <!-- Ticket + Flexfone -->

    <!--<div class="row">
      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-title">
            <h2>~Gennemførselstid</h2>
            <i class="fas fa-ticket-alt"></i>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-around">
              <p class="count text-darkgrey">02:50<small>t</small></p>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex">
              <p class="vs-stats"><i class="fas fa-angle-up text-green"></i><span class="text-green">Hurtigere</span><i
                class="fas fa-exchange-alt"></i><span>Igår</span></p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-title">
            <h2>~Svartid</h2>
            <i class="fas fa-phone"></i>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-around">
              <p class="count text-darkgrey">00:50<small>m</small></p>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex">
              <p class="vs-stats"><i class="fas fa-angle-up text-green"></i><span class="text-green">Hurtigere</span><i
                class="fas fa-exchange-alt"></i><span>Igår</span></p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mb-3">
        <div class="card">
          <div class="card-title">
            <h2>~Længde</h2>
            <i class="fas fa-phone"></i>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-around">
              <p class="count text-darkgrey">33:33<small>m</small></p>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex">
              <p class="vs-stats"><i class="fas fa-angle-up "></i><span class="">Længere</span><i
                class="fas fa-exchange-alt"></i><span>Igår</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <!-- Flexfone -->

    <div class="row">
        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Samtaler</h2>
                    <i class="fas fa-phone"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-blue">{{ticket?.callsToday}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up text-green"></i><span
                                class="text-green">5 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>~Længde</h2>
                    <i class="fas fa-phone"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-darkgrey">{{ticket?.callsDuration}}<small>m</small></p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up "></i><span class="">Længere</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="col-lg-6 mb-3">
          <div class="card">
            <div class="card-title">
              <h2>Misset opkald</h2>
              <i class="fas fa-phone"></i>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-around">
                <p class="count text-red">1</p>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex">
                <p class="vs-stats"><i class="fas fa-angle-up text-red "></i><span class="text-red">1 idag</span><i
                  class="fas fa-exchange-alt"></i><span>Igår</span></p>
              </div>
            </div>
          </div>
        </div>-->
    </div>

    <!-- Tilbud -->

    <div class="row">
        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Godkendte</h2>
                    <i class="fas fa-copy"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-green">{{ticket?.offerApproved}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up text-green"></i><span
                                class="text-green">1 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Oprettet</h2>
                    <i class="fas fa-copy"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-darkgrey">{{ticket?.offerCreated}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up text-green"></i><span
                                class="text-green">2 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Overskredet</h2>
                    <i class="fas fa-copy"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-red">{{ticket?.offerOverDue}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-angle-up text-red"></i><span class="text-red">2 idag</span><i
                                class="fas fa-exchange-alt"></i><span> 7 dage</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-3 mb-3">
            <div class="card">
                <div class="card-title">
                    <h2>Afventende</h2>
                    <i class="fas fa-copy"></i>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <p class="count text-darkgrey">{{ticket?.offerWaiting}}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex">
                        <p class="vs-stats"><i class="fas fa-ellipsis-h "></i><span class="">0 idag</span><i
                                class="fas fa-exchange-alt"></i><span>Igår</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
