import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BasicFunctionService} from './basicFunction.service';
import {TvstatTicket} from "./models/tvstatTicket";
import {PriorityCounter} from "./models/priorityCounter";

@Injectable({
  providedIn: 'root'
})

export class TicketService {

  constructor(private http: HttpClient, private basicFunctionsService: BasicFunctionService) {
  }

  GetTicketInformation() {
    return this.http.get<TvstatTicket>(this.basicFunctionsService.baseApiUrl + 'ticket', this.basicFunctionsService.requestOptions);
  }

  GetSagitoTicketInformation() {
    return this.http.get<TvstatTicket>(this.basicFunctionsService.baseApiUrl + 'sagitoTicket', this.basicFunctionsService.requestOptions);
  }


  GetAcceloTicketInformation() {
    return this.http.get<PriorityCounter>(this.basicFunctionsService.baseApiUrl + 'acceloTicket', this.basicFunctionsService.requestOptions);
  }
}
